
function Footer() {
  return (
    <div id='footer-container'>

    </div>
  )
}

export default Footer
